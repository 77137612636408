<template>
  <div id="metaboliteidentification_diy">
    <MyheadCompnent></MyheadCompnent>
    <MetaboliteIdentificationComponent_diy></MetaboliteIdentificationComponent_diy>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
  import MetaboliteIdentificationComponent_diy from '@/components/Browse/MetaboliteIdentificationComponent_diy.vue'
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  export default {
    name: 'MetaboliteIdentification_diy',
    data () {
      return {

      }
    },
    components: {
      MetaboliteIdentificationComponent_diy,
      MyheadCompnent,
      MyfooterCompnent,
    },
  }
</script>

<style scoped lang="scss">
</style>