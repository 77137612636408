<template>
  <div>
    <div id="metaboliteidentificationcomponent">
      <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
      <el-row>
        <el-col :span="1">
          <div class="grid-content bg-purple"></div>
        </el-col>
        <el-col :span="22">
          <div>
            <h2>Individual</h2>
            <div>
              <el-input v-model="msTexts" :rows="5" type="textarea"
                placeholder="Please input ms data. Fragement and Intensity need to be separated with a tab.&#13;&#10;Each line contains 'ID','Q1','Q1 Intensity','RT','Q3','Q3 Intensity' ..."
                :clearable="true" />
              <el-collapse>
                <el-collapse-item title="Parameters" name="1">
                  <!-- <template #title>
                      <h3>Parameters</h3>
                    </template> -->
                  <div class="margin">
                    <h3>ESI mode</h3>
                    <el-select v-model="mode_individual" class="m-2" placeholder="Select">
                      <el-option key="pos" label="pos" value="P"></el-option>
                      <el-option key="neg" label="neg" value="N"></el-option>
                    </el-select>
                  </div>
                  <div class="margin">
                    <h3>Q1 threshold (ppm)</h3>
                    <el-input-number v-model="thresholdQ1_individual" :min="1" :max="100" />
                  </div>
                  <div class="margin">
                    <h3>Qn threshold (ppm)</h3>
                    <el-input-number v-model="thresholdQn_individual" :min="1" :max="100" />
                  </div>
                </el-collapse-item>
              </el-collapse>
              <div style="margin: 10px 5px 10px 0px; display: inline-block">
                <el-button type="primary" @click="damoDatas">Demo
                  <Document style="width:12; height:12" />
                </el-button>
                <el-button type="primary" @click="transform">Transform
                  <Search style="width:12; height:12" />
                </el-button>
              </div>

            </div>
            <el-row>
              <el-col :span="9">
                <h3
                  style="text-align:center;line-height:50px; background-color: #b3c0d1; margin: 0px 0px 0px 0px; height: 50px;">
                  Feature list
                </h3>
                <el-table :data="msList" style="width: 100%" @row-click="detailSearch" max-height="300">
                  <el-table-column prop="ID" label="ID" sortable></el-table-column>
                  <el-table-column prop="Q1" width="95" label="Q1" sortable></el-table-column>
                  <el-table-column prop="RT" width="60" label="RT" sortable></el-table-column>
                  <el-table-column prop="Q3" width="95" label="Q3" sortable></el-table-column>
                </el-table>
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="14">
                <div>
                  <h3 v-show="searchLoading && individualCandidateList.length==0"
                    style="text-align:center;line-height:50px; background-color: #b3c0d1; margin: 0px 0px 0px 0px; height: 50px;">
                    Waiting for library searching results !
                  </h3>
                </div>
                <div v-show="individualCandidateList.length>0">
                  <h3
                    style="text-align:center;line-height:50px; background-color: #b3c0d1; margin: 0px 0px 0px 0px; height: 50px;">
                    Results
                  </h3>

                  <el-table :data="individualCandidateList" style="width: 100%" @row-click="plotalign" max-height="300">
                    <el-table-column label="PMhub ID" width="130">
                      <template #default="scope">
                        <!-- <p>{{ scope.row.featureID }}</p> -->
                        <a :href="$store.state.frontbaseURL+'metabolitedetail/'+scope.row.metabolite">{{
                          scope.row.metabolite
                          }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column prop="molecular" label="molecular"></el-table-column>
                    <el-table-column prop="name" label="name"></el-table-column>
                    <el-table-column label="Ms ID" width="200">
                      <template #default="scope">
                        <router-link
                          :to="{name:'MsDetail',params:{metabolite:scope.row.metabolite,msID:scope.row.feature}}">
                          {{ scope.row.feature }}
                        </router-link>
                      </template>
                    </el-table-column>
                    <el-table-column prop="dotproduct" label="dotproduct"></el-table-column>
                  </el-table>
                </div>

              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div v-show="ms2_individual.length>0">
                  <h3
                    style="text-align:center;line-height:50px; background-color: #b3c0d1; margin: 0px 0px 0px 0px; height: 50px;">
                    MS/MS plot
                  </h3>
                  <div v-show="ms2_individual.length>0" id="msplot" style="border: 1px solid #b3c0d1;"></div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <h2>Batch</h2>
            <el-row>
              <el-col :span="5">
                <el-upload ref="upload" class="upload-demo" drag :headers="headers" :data="batchData"
                  :action="$store.state.backendbaseURL+'search/feature/batch/'" multiple :auto-upload="false"
                  :before-upload="beforeUpload" :on-success="onSuccess" :on-error="onError">
                  <el-icon class="el-icon--upload" :size="120" color="#409EFC">
                    <upload-filled />
                  </el-icon>
                  <div class="el-upload__text">
                    Drop file here or <em>click to upload</em><br />
                    'mgf' file with a size less than 30 Mb<br />
                    'txt' file with a size less than 10 Mb
                  </div>
                </el-upload>
                <el-collapse>
                  <el-collapse-item title="Parameters" name="1">
                    <div class="margin">
                      <h3>File type</h3>
                      <div>
                        <el-select v-model="batchData.fileType" class="m-2" placeholder="Select">
                          <el-option key="mgf" label="mgf" value="mgf"></el-option>
                          <el-option key="txt" label="txt" value="txt"></el-option>
                        </el-select>
                        <el-link v-if="batchData.fileType==='txt'" download='damo.txt'
                          :href="this.$store.state.demoURL + 'pos.txt'" target="_blank">Demo txt
                          file
                          <Download style="width:12; height:12" />
                        </el-link>
                        <el-link v-if="batchData.fileType==='mgf'" download='damo.mgf'
                          :href="this.$store.state.demoURL + 'pos.mgf'" target="_blank">Demo mgf
                          file
                          <Download style="width:12; height:12" />
                        </el-link>
                      </div>
                    </div>
                    <div class="margin">
                      <h3>ESI mode</h3>
                      <el-select v-model="batchData.mode" class="m-2" placeholder="Select">
                        <el-option key="pos" label="pos" value="pos"></el-option>
                        <el-option key="neg" label="neg" value="neg"></el-option>
                      </el-select>
                    </div>
                    <div class="margin">
                      <h3>Q1 threshold (ppm)</h3>
                      <el-input-number v-model="batchData.ppmQ1" :min="1" :max="100" />
                    </div>
                    <div class="margin">
                      <h3>Qn threshold (ppm)</h3>
                      <el-input-number v-model="batchData.ppmQn" :min="1" :max="100" />
                    </div>
                    <div class="margin">
                      <h3>Duplicate</h3>
                      <el-select v-model="batchData.duplicate" class="m-2" placeholder="Select">
                        <el-option key="1" label="Duplicate" value="1"></el-option>
                        <el-option key="0" label="No duplicate" value="0"></el-option>
                      </el-select>
                    </div>
                  </el-collapse-item>
                </el-collapse>
                <div style="margin: 10px 5px 10px 0px; display: inline-block">
                  <el-button type="primary" :loading="submitLoading" @click="submitUpload">Search
                    <Search style="width:12; height:12" />
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <h2>Structure generate</h2>
            <el-input v-model="smiles" type="textarea" placeholder="Please input SMILES" :clearable="true" />
            <canvas id="draw2d" width="300" height="300" v-show="smiles"></canvas>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<!--  prop="metabolite" -->
<script>
  import request from '@/network/request'
  import $ from 'jquery'
  import ChemDoodle from "../../mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  import { ref } from 'vue'
  import common from '@/mymethods/common.js'
  import { Edit, Upload, Picture, Search, UploadFilled, Histogram, Download, Document } from '@element-plus/icons-vue'
  import * as d3 from 'd3'
  import { ElMessage, ElLoading } from 'element-plus'
  import 'smiles-drawer'
  let canvas
  let context1
  export default {
    name: 'MetaboliteIdentificationComponent',
    data () {
      return {
        msText: '',
        msTexts: '',
        msList: [],
        msDict: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.access,
        },
        step: 0,
        q1_individual: '',
        ms2_individual: [],
        thresholdQ1_individual: 10,
        thresholdQn_individual: 10,
        mode_individual: "P",
        individualCandidateList: [],
        individualCandidateMsDict: {},
        loading: false,
        searchLoading: false,
        batchData: {
          mode: "pos",
          ppmQ1: 10,
          ppmQn: 10,
          timeNow: new Date(),
          duplicate: "1",
          fileType: "mgf",
        },
        submitLoading: false,
        head: "Feature Search",
        loadingTotal: "",
        smiles: "",
      }
    },
    methods: {
      test () {
        this.count += 1
      },
      beforeUpload (file) {
        this.loadingTotal = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        if (this.batchData.fileType === 'mgf') {
          var fileSizeThreshold = 30
        } else {
          var fileSizeThreshold = 10
        }
        var isLt2M = file.size / 1024 / 1024 < fileSizeThreshold
        if (!isLt2M) {
          var messageT = '[' + file.name + '] 大小超过' + fileSizeThreshold + ' MB '
          ElMessage({
            message: messageT,
            type: 'error',
          })
        }
        return isLt2M
      },
      onSuccess (res) {
        ElMessage({
          message: 'Congrats, upload successfully. Please wait for the results',
          type: 'success',
          showClose: true,
          duration: 0,
        })
        this.loadingTotal.close()
      },
      onError (res) {
        this.loadingTotal.close()
        ElMessage({
          message: 'You have to login !',
          type: 'error',
        })
      },
      submitUpload () {
        this.$refs.upload.submit()
        // 写view功能
      },
      damoDatas () {
        this.msTexts = "m001\t184.0604\t1000\t3\t166.0499\t100\t148.0393\t1.63\t167.0528\t0.51\nm002\t184.0604\t1000\t4\t166.0499\t100\t148.0393\t1.63\t167.0528\t0.51\n"
        this.q1_individual = "184.0604"
      },
      damoData () {
        this.msText = "166.0499 100\n148.0393 1.63\n167.0528 0.51\n"
        this.q1_individual = "184.0604"
      },
      plotms () {
        var msListTemp = this.msText.split("\n")
        while (msListTemp[msListTemp.length - 1] == "") {
          msListTemp.pop()
        }
        if (this.msText.includes("\t")) {
          var splitStr = "\t"
          // 标记
        } else {
          var splitStr = " "
        }
        this.ms2_individual = []
        for (let i in msListTemp) {
          this.ms2_individual = this.ms2_individual.concat(msListTemp[i].split(splitStr))
        }
        for (let i in this.ms2_individual) {
          this.ms2_individual[i] = Number(this.ms2_individual[i])
        }
        var q1 = Number(this.q1_individual)
        common.drawMsData(this.ms2_individual, "#msplot", q1, 1000, 300, 50, 0, "18px", "18px")
      },
      detailSearch (row, column, event) {
        var metaboliteID = row.ID
        this.q1_individual = this.msDict[metaboliteID][0][1]
        this.ms2_individual = []
        var ms2_individualT = this.msDict[metaboliteID][1]
        for (let i in ms2_individualT) {
          for (let j in ms2_individualT[i]) {
            this.ms2_individual.push(ms2_individualT[i][j])
          }
        }
        common.drawMsData(this.ms2_individual, "#msplot", this.q1_individual, 1000, 300, 50, 0, "18px", "18px")
        this.search()
      },
      plotalign (row, column, event) {
        var ms2T = this.individualCandidateMsDict[row.feature]
        var ms2 = []
        for (let i in ms2T) {
          if (ms2T[i]) {
            ms2.push(ms2T[i])
          }
        }
        var q1Library = Number(ms2[2])
        ms2 = ms2.splice(4)
        var q1 = Number(this.q1_individual)
        common.drawMsAlign(this.ms2_individual, ms2, "#msplot", q1, q1Library, 1000, 300, 50, 0, "18px", "18px")
      },
      transform () {
        this.msList = []
        this.msDict = {}
        var msTexts1 = this.msTexts.split("\n")
        while (msTexts1.slice(-1) == "") {
          msTexts1.pop()
        }
        for (let i = msTexts1.length - 1; i >= 0; i--) {
          if (msTexts1[i] == "") {
            msTexts1.splice(i, 1)
          }
        }
        for (let i in msTexts1) {
          var msTexts2 = msTexts1[i].split("\t")
          while (msTexts2.slice(-1) == "") {
            msTexts2.pop()
          }
          msTexts2[1] = Math.round(Number(msTexts2[1]) * 10000) / 10000
          for (let ii = 2; ii < msTexts2.length; ii++) {
            msTexts2[ii] = Math.round(Number(msTexts2[ii]) * 10000) / 10000
          }
          var ms2T = []
          var maxIntensity = 0
          for (let i = 4; i < msTexts2.length; i += 2) {
            ms2T.push([msTexts2[i], msTexts2[i + 1]])
            if (msTexts2[i + 1] > maxIntensity) {
              maxIntensity = msTexts2[i + 1]
            }
          }
          for (let i = ms2T.length - 1; i >= 0; i--) {
            ms2T[i][1] = Math.round(ms2T[i][1] / maxIntensity * 100)
            if (ms2T[i][1] < 1) {
              ms2T.splice(i, 1)
            }
          }
          this.msList.push({ ID: msTexts2[0], Q1: msTexts2[1], RT: msTexts2[3], Q3: msTexts2[4] })
          this.msDict[msTexts2[0]] = [[msTexts2[0], msTexts2[1], msTexts2[2], msTexts2[3]], ms2T]
        }
      },
      search () {
        // this.plotms()
        var seachParams = { q1: this.q1_individual, ms2_individual: this.ms2_individual.join("\t"), thresholdQ1: this.thresholdQ1_individual, thresholdQn: this.thresholdQn_individual, mode: this.mode_individual }
        this.searchLoading = true
        this.individualCandidateList = []
        this.individualCandidateMsDict = {}
        // 写view功能
        if (!seachParams.q1 == "" && seachParams.ms2_individual.length > 0) {
          request({
            url: 'search/feature/individual/',
            params: seachParams,
          }).then(res => {
            let resultTemp = res.metaboliteList.sort((a, b) => b.dotproduct - a.dotproduct)
            this.individualCandidateList = resultTemp
            this.individualCandidateMsDict = common.list2dict2(res.ms, "msID")
            // this.individualCandidateMsDict = res.ms
            this.searchLoading = false
          }).catch(err => {
            console.log(err);
            this.searchLoading = false
          });
        } else {
          this.searchLoading = false
        }
      },
      demo () {
      },
      plotStructureFromSmiles (smiles, idT) {
        let options = {
          width: 500,
          height: 500,
          compactDrawing: false
        }
        let smilesDrawer = new SmilesDrawer.Drawer(options);
        // smilesDrawer.draw('N=C(O)CC', 'draw2d')
        SmilesDrawer.parse(smiles, function (tree) {
          smilesDrawer.draw(tree, idT, 'light', false);
        }, function (err) {
          console.log(err);
        })
      }
    },
    components: {
      MetaboliteBrowseCompnentSearch,
      Edit,
      Upload,
      Picture,
      Search,
      UploadFilled,
      Histogram,
      Download,
      Document,
    },
    created () {

    },
    mounted () {
      window.vue = this
      var that = this
      this.timer = setInterval(function () {
        var d = new Date()
        that.batchData.timeNow = String(d.getFullYear()) +
          ("0" + String(d.getMonth() + 1)).slice(-2) +
          ("0" + String(d.getDate())).slice(-2) +
          ("0" + String(d.getHours())).slice(-2) +
          ("0" + String(d.getMinutes())).slice(-2) +
          ("0" + String(d.getSeconds())).slice(-2)
      }, 1000)
    },
    updated () {

    },
    beforeDestroy () {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    // 检测querydata的编号
    watch: {
      smiles: {
        handler (val, olVal) {
          this.plotStructureFromSmiles(val, "draw2d")
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  #locsearchcomponent {
    min-height: 800px
  }

  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
  }

  .el-select .el-input {
    width: 180px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-table {
    cursor: pointer;
  }



  .el-input {
    margin: 1px 0px 3px 0px;
  }

  h3 {
    height: 25px;
    margin: 30px 0px 20px 0px;
  }

  .margin {
    margin: 10px 5px 10px 0px
  }
</style>